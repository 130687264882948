import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import classes from "./ReservationsByChannelPie.module.scss";
import { periodList } from "../../../../../utils/constants/periodLists";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { GET_RESERVATIONS_BY_CHANNEL_REQUEST } from "../../../../../redux/actions";
import {
  REVENUE_PIE_COLORS,
  RADIAN
} from "../../../../../utils/constants/chartConstants";
import { Divider } from "@mui/material";
import { L12M } from "../../../../../utils/constants/filterConstants";
import { reservationsPerChannelSelector } from "../../../../../redux/selectors/revenueSelector";
import { toWholePercent } from '../../../../../utils/commonUtils';
import { Toast } from "../../../../common/Toast/Toast";
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";

const getOccupancyPieLegend = (isMobileView: boolean) => [
  {
    label: isMobileView ? "Booking" : "Booking.com",
    color: "#00B48D",
    legendType: "circle"
  },
  {
    label: "Vrbo",
    color: "#5D6293",
    legendType: "circle"
  },
  {
    label: "Airbnb",
    color: "#02094F",
    legendType: "circle"
  },
  {
    label: "Expedia",
    color: "#B4B7CF",
    legendType: "circle"
  }
];

const renderCustomizedLabel = (props: any) => {
  const { cx, cy, midAngle, middleRadius, outerRadius, percent } = props;
  if (!percent) return;

  let radius = middleRadius;

  if (percent <= 0.25) {
   radius = outerRadius * 0.6;
  }

  if (percent <= 0.15) {
    radius = outerRadius * 0.7;
  }

  let x = cx + radius * Math.cos(-midAngle * RADIAN);
  let y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ fontWeight: 500, fontSize: percent < 0.1 ? '12px' : '16px' }}
    >
      {`${toWholePercent(percent)}%`}
    </text>
  );
};

export const ReservationsByChannelPie = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L12M);
  const reservationsData = useSelector(
    reservationsPerChannelSelector(period)
  );
  const isMobileView = useSelector(isMobileModeSelector);
  const { booking, expedia, vrbo, airbnb } = reservationsData || {};

  const data = [
    { name: "Booking.com", value: booking || 0, color: '#00B48D' },
    { name: "Vrbo", value: vrbo || 0, color: '#5D6293' },
    { name: "Airbnb", value: airbnb || 0, color: '#02094F' },
    { name: "Expedia", value: expedia || 0, color: '#B4B7CF' }
  ];

  useEffect(() => {
    dispatch({
      type: GET_RESERVATIONS_BY_CHANNEL_REQUEST,
      payload: {
        param: period
      }
    });
  }, [dispatch, period]);

  return (
    <div className={classes.revenuePieContainer}>
      <div className={classes.revenuePieHeader}>
        <h4>Revenue Distribution by Channel</h4>
        <PeriodDropdown
          className={classes.dropdown}
          period={period}
          periodList={periodList}
          setPeriod={setPeriod}
        />
      </div>
      {!isMobileView ? <Divider /> : null}
      <div className={classes.pieContainer}>
        {booking || expedia || vrbo || airbnb ? (
          <>
            <ResponsiveContainer width={isMobileView ? "100%" : "60%"} height={isMobileView ? 206 : "90%"}>
              <PieChart width={206} height={206}>
                <Pie
                  cx={"50%"}
                  cy={"50%"}
                  data={data}
                  dataKey="value"
                  fill="#8884d8"
                  isAnimationActive={false}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={isMobileView ? 103 : 95}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                      stroke="none"
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            {isMobileView ? (
              <div className={classes.legendBlock}>
                <Legend
                  className={`${classes.legend} ${classes.legendRow}`}
                  legendData={getOccupancyPieLegend(isMobileView).slice(0,2)}
                />
                <Legend
                  className={`${classes.legend} ${classes.legendRow}`}
                  legendData={getOccupancyPieLegend(isMobileView).slice(2,4)}
                />
              </div>
            ) : (
              <Legend
                className={classes.legend}
                legendData={getOccupancyPieLegend(isMobileView)}
              />
            )}
          </>
        ) : (
          <Toast type="info" />
        )}
      </div>
    </div>
  );
};
