import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import classes from "./RatingHighlights.module.scss";
import { GET_REVIEWS_REQUEST } from "../../../../../redux/actions";
import { L12M, L3060D, L30D, N30D } from '../../../../../utils/constants/filterConstants';
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { isNumber } from "../../../../../utils/commonUtils";
import { Highlight } from "../../../../common/BasicHighlight/BasicHighlight";
import {
  ratingErrorSelector,
  ratingLoadingSelector,
  ratingSelector
} from "../../../../../redux/selectors/ratingSelector";
import { Loader } from "../../../../common/Loader/Loader";

export const periodList = [
  {
    id: L30D,
    label: L30D
  },
  {
    id: L12M,
    label: L12M
  }
];

export const RatingHighlights = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L12M);
  const previousPeriod = period === N30D ? L12M : L3060D;

  const data = useSelector(ratingSelector(period));
  const previousData = useSelector(ratingSelector(previousPeriod));

  const {
    overall_ratings,
    value_rating,
    cleaness,
    number_of_reviews,
    l30_60d_overall_ratings,
    l30_60d_value_rating,
    l30_60d_cleaness,
    l30_60d_number_of_reviews
  } = data || {};
  const {
    overall_ratings: previous_overall_ratings,
    value_rating: previous_value_rating,
    cleaness: previous_cleaness,
    number_of_reviews: previous_number_of_reviews
  } = previousData || {};

  const isLoading = useSelector(ratingLoadingSelector);
  const isError = useSelector(ratingErrorSelector);

  useEffect(() => {
    dispatch({
      type: GET_REVIEWS_REQUEST,
      payload: { param: period }
    });
  }, [dispatch, period]);

  return (
    <div className={classes.ratingHighlightsWrapper}>
      <>
        <div className={classes.ratingHighlightsHeader}>
          <h4>Rating</h4>
          <PeriodDropdown
            className={classes.dropdown}
            period={period}
            periodList={periodList}
            setPeriod={setPeriod}
          />
        </div>
        <Loader isLoading={isLoading} isError={isError} data={data} />
        {data && (
          <div className={classes.highlights}>
            {isNumber(overall_ratings) && (
              <>
                <Highlight
                  label="Overall Rating"
                  rating
                  tooltip={
                    "This is the average score given to your property compared to every other property listed on Online Travel Agencies, Airbnb and Booking.com. This number takes into account your property’s cleanliness, value, location, listing accuracy, staff, facilities, communication, check-in simplicty and comfort"
                  }
                  value={overall_ratings}
                  previousValue={l30_60d_overall_ratings}
                  showDynamicIndicator={period !== L12M}
                  isDecimal
                />
                <Divider flexItem orientation="vertical" />
              </>
            )}
            {isNumber(value_rating) && (
              <>
                <Highlight
                  label="Value"
                  rating
                  tooltip={""} //TODO: ask for text
                  value={value_rating}
                  previousValue={l30_60d_value_rating}
                  showDynamicIndicator={period !== L12M}
                  isDecimal
                />
                <Divider flexItem orientation="vertical" />
              </>
            )}
            {isNumber(cleaness) && (
              <>
                <Highlight
                  label="Cleanliness"
                  rating
                  tooltip={""} //TODO: ask for text
                  value={cleaness}
                  previousValue={l30_60d_cleaness}
                  showDynamicIndicator={period !== L12M}
                  isDecimal
                />
                <Divider flexItem orientation="vertical" />
              </>
            )}
            {isNumber(Number(number_of_reviews)) && (
              <Highlight
                label="Number of Reviews"
                tooltip={""} //TODO: ask for text
                value={number_of_reviews}
                previousValue={l30_60d_number_of_reviews}
                showDynamicIndicator={period !== L12M}
              />
            )}
          </div>
        )}
      </>
    </div>
  );
};
