import classes from "./ListingsPage.module.scss";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { IListing, IListingRow } from "../../interfaces";
import { useSelector } from "react-redux";
import { userListingsSelector } from "../../../redux/selectors/userDataSelector";
import { ExternalLinkActive } from "../../../assets/icons/ExternalLinkActive";
import { ExternalLinkInactive } from "../../../assets/icons/ExternalLinkInactive";
import { AirbnbIcon } from "../../../assets/icons/AirbnbIcon";
import { VrboIcon } from "../../../assets/icons/VrboIcon";
import { ExpediaIcon } from "../../../assets/icons/ExpediaIcon";
import { BookingIcon } from "../../../assets/icons/BookingIcon";
import { processListings } from "../../../utils/dataProcessingUtils";
import { AIRBNB, BOOKING, EXPEDIA, VRBO } from "../../../utils/constants/commonConstants";
import {isMobileModeSelector} from "../../../redux/selectors/appStatusSelector";

const channelToIconMap: any = {
  [AIRBNB]: {
    icon: <AirbnbIcon />,
    name: "AirBnb",
  },
  [BOOKING]: {
    icon: <BookingIcon />,
    name: "Booking",
  },
  [EXPEDIA]: {
    icon: <ExpediaIcon />,
    name: "Expedia",
  },
  [VRBO]: {
    icon: <VrboIcon />,
    name: "Vrbo",
  },
}

export const ListingsPage = () => {
  const listings: IListing[] = useSelector(userListingsSelector);
  const isMobileView = useSelector(isMobileModeSelector);

  const processedListings: IListingRow[] | null = listings ? processListings(listings) : null;

  return (
    <FullPageContainer className={classes.listingsWrapper}>
      <div className={classes.listingsTableContainer}>
        <h4>My Listings</h4>
        <Table aria-label="Listings table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align={isMobileView ? "center" : "left"}>Channel</TableCell>
              <TableCell align={isMobileView ? "center" : "left"}>Link</TableCell>
              <TableCell align={isMobileView ? "center" : "left"}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {processedListings &&
              processedListings.map(
                ({ active, channel, link, listing_id }: IListingRow, index: number) => {
                  return (
                    <TableRow className={classes.tableRow} key={`${listing_id}_${index}`}>
                      <TableCell align={isMobileView ? "center" : "left"}>
                        <span className={classes.channelCell}>
                          <span className={classes.channelCell}>{channelToIconMap[channel]?.icon}{channelToIconMap[channel]?.name}</span>
                        </span>
                      </TableCell>
                      <TableCell align={isMobileView ? "center" : "left"}>
                        {active ? (
                          <a
                            className={classes.listingLink}
                            href={link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {isMobileView ? "Listing" : "View Live Listing"}
                            <ExternalLinkActive/>
                          </a>
                        ) : (
                          <p
                            className={`${classes.listingLink} ${classes.inactive}`}
                          >
                            {isMobileView ? "Listing" : "View Live Listing"}
                            <ExternalLinkInactive/>
                          </p>
                        )}
                      </TableCell>
                      <TableCell align={isMobileView ? "center" : "left"}>
                        {active ? (
                          <div className={classes.activeBadge}>
                            Active<span></span>
                          </div>
                        ) : (
                          <div className={classes.inactiveBadge}>
                            Inactive<span></span>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
      </div>
    </FullPageContainer>
  );
};
