import { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput
} from "@mui/material";
import classes from "./LoginForm.module.scss";
import { Visibility } from "../../../../../assets/icons/Visibility";
import { CheckboxIcon } from "../../../../../assets/icons/CheckboxIcon";
import {
  FORGOT_PASSWORD_REQUEST,
  LOGIN_USER_REQUEST
} from "../../../../../redux/actions";
import { ForgotPasswordModal } from "../ForgotPasswordModal/ForgotPasswordModal";
import {isMobileModeSelector} from "../../../../../redux/selectors/appStatusSelector";

export const LoginForm = (props: { isLoading: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isMobileView = useSelector(isMobileModeSelector);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCloseModal = () => setOpenModal(open => !open);

  const handleLogin = () => {
    dispatch({
      type: LOGIN_USER_REQUEST,
      payload: { property_id: email, password, navigate }
    });
    setSubmitted(true);
  };

  const handleChangePassword = () => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
      payload: { property_id: email }
    });
  };

  return (
    <div className={classes.loginFormContainer}>
      <h2>Welcome to your Owner’s Dashboard!</h2>
      <p>Enter your credentials to access the account</p>
      <FormControl fullWidth>
        <OutlinedInput
          id="email"
          className={classes.input}
          fullWidth
          name="email"
          onChange={event => setEmail(event.target.value)}
          placeholder="Enter your user"
          type="email"
          value={email}
        />
      </FormControl>
      <FormControl fullWidth>
        <OutlinedInput
          id="password"
          className={classes.input}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth
          name="password"
          onChange={event => setPassword(event.target.value)}
          placeholder="Enter your password"
          type={showPassword ? "text" : "password"}
          value={password}
        />
      </FormControl>
      <FormControl fullWidth>
        <div className={classes.formGroup}>
          <FormControlLabel
            className={classes.rememberme}
            control={<Checkbox color="primary" icon={<CheckboxIcon />} />}
            label="Remember me"
          />
          <Link
            className={classes.forgotAction}
            component="button"
            variant="body2"
            onClick={() => handleCloseModal()}
          >
            Forgot your password?
          </Link>
        </div>
        <Button
          sx={{ borderRadius: isMobileView ? '24px' : '16px', height: isMobileView ? '62px' : '56px' }}
          className={classes.action}
          color="primary"
          onClick={handleLogin}
          variant="contained"
        >
          {submitted && props.isLoading ? (
            <CircularProgress classes={{ root: classes.loader }} />
          ) : (
            "Sign in"
          )}
        </Button>
      </FormControl>
      <ForgotPasswordModal
        open={openModal}
        handleClose={handleCloseModal}
        handleChangePassword={handleChangePassword}
      />
    </div>
  );
};
