import {
  format,
  isWithinInterval,
  differenceInDays,
  isAfter,
  isBefore,
} from "date-fns";
import classes from "./RenderMonth.module.scss";
import {
  oneLetterWeekDayNames,
  shortWeekDayNames,
  weekDayNames,
} from "../../../../../../../utils/constants/periodLists";
import {
  IBasicTooltip,
  ICalendarMonthView,
  IReservation,
} from "../../../../../../interfaces";
import { Legend } from "../../../../../../common/ChartLegend/ChartLegend";
import { getDates } from "../../../../../../../utils/calendarUtils";
import { useSelector } from "react-redux";
import { allReservations } from "../../../../../../../redux/selectors/reservationsData";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { LightInfoIcon } from "../../../../../../../assets/icons/LightInfoIcon";
import { AirbnbIcon } from "../../../../../../../assets/icons/AirbnbIcon";
import { BookingIcon } from "../../../../../../../assets/icons/BookingIcon";
import { ExpediaIcon } from "../../../../../../../assets/icons/ExpediaIcon";
import { VrboIcon } from "../../../../../../../assets/icons/VrboIcon";
import { ReservationsAPI } from "../../../../../../../api/reservationsAPI";
import { isMobileModeSelector } from "../../../../../../../redux/selectors/appStatusSelector";
import { calendarLegend } from "../../Calendar";
import { useState } from "react";
import { toPrettyNumber } from "../../../../../../../utils/commonUtils";

const channelToIconMap = (channel: string) => {
  switch (channel) {
    case "airbnb2": {
      return <AirbnbIcon />;
    }
    case "Booking.com": {
      return <BookingIcon />;
    }
    case "Booking Engine": {
      return <BookingIcon />;
    }
    case "VRBO": {
      return <VrboIcon />;
    }
    case "HomeAway CA": {
      return <VrboIcon />;
    }
    case "expedia": {
      return <ExpediaIcon />;
    }
    default: {
      return "";
    }
  }
};

const CustomTooltip = ({ tooltip, className }: IBasicTooltip) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        className={className}
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        placement="top"
        title={tooltip}
        open={open}
      >
        <IconButton onClick={handleTooltipOpen} onMouseOver={handleTooltipOpen} onMouseOut={handleTooltipClose}>
          <LightInfoIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

const getOwnerBlockName = (name?: string) => {
  const nameSplitByHyphen =  name?.split('-')?.[1];
  const nameSplitBySpace = nameSplitByHyphen?.split(' ')?.filter(v => !!v);

  return `${nameSplitBySpace?.[0]} ${nameSplitBySpace?.[1]}`;
}

export const RenderMonth = ({
  calendarData,
  currentMonth,
  currentYear,
  reservationsData,
  small,
  year,
  onClick,
  isLeftSide,
}: ICalendarMonthView) => {
  const allReservationsData = useSelector(allReservations);
  const isMobileView = useSelector(isMobileModeSelector);
  const dateList = calendarData
    .flat()
    .map((date: Date) => format(date, "yyyy-MM-dd"));
  const currentMonthDateMap = dateList.reduce(
    (obj: any, date: any) => ({
      [date]: allReservationsData
        ?.filter((reservation: any) => reservation.status !== "canceled")
        .filter((reservation: any) =>
          isWithinInterval(new Date(date), {
            start: new Date(reservation.checkin_at.split(" ")[0]),
            end: new Date(reservation.checkout_at.split(" ")[0]),
          })
        ),
      ...obj,
    }),
    {}
  );

  const weekDays =
    small && isMobileView
      ? oneLetterWeekDayNames
      : small || isMobileView
      ? shortWeekDayNames
      : weekDayNames;
  const reservationsDataForCurrentMonth =
    reservationsData?.[currentYear]?.[currentMonth];
  const datesWithReservations: any = {};

  if (reservationsDataForCurrentMonth) {
    Object.values(reservationsDataForCurrentMonth).forEach(
      (reservation: any) => {
        const startReservDay = new Date(reservation.checkin_at);
        const endReservDay = new Date(reservation.checkout_at);
        const rangeDates = getDates(startReservDay, endReservDay);
        rangeDates.forEach((date) => {
          datesWithReservations[date.getDate()] = reservation;
        });
      }
    );
  }

  return (
    <>
      <div
        className={`${classes.monthBody} ${small && classes.small} ${
          isLeftSide && classes.leftSide
        }`}
        onClick={onClick}
      >
        <div
          className={`${classes.dayWeekSection} ${
            isLeftSide && classes.leftSide
          }`}
        >
          {weekDays.map((dayName, index) => (
            <div className={classes.dayWeek} key={`${dayName}_${index}`}>
              {dayName}
            </div>
          ))}
        </div>
        <div className={classes.month}>
          {calendarData.map((week: any) => (
            <div
              className={`${classes.weekSection} ${
                isLeftSide && classes.leftSide
              }`}
              key={week}
            >
              {week.map((day: Date, dayIndex: number) => {
                const currentDay = format(day, "yyyy-MM-dd");
                const currentDayReservations = currentMonthDateMap[currentDay];
                const currentDayCheckin = currentDayReservations?.find(
                  (reservation: any) =>
                    reservation.checkin_at.split(" ")?.[0] === currentDay
                );
                const ownerBlockName = getOwnerBlockName(currentDayCheckin?.created_by);
                const dayNumber = format(day, "d");
                const isPaid = currentDayReservations?.some(
                  (reservation: any) => reservation?.netincome
                );
                const isPaidCheckin = currentDayReservations?.find(
                  (reservation: any) =>
                    reservation.checkin_at.split(" ")?.[0] === currentDay
                )?.netincome;
                const isPaidCheckout = currentDayReservations?.find(
                  (reservation: any) =>
                    reservation.checkout_at.split(" ")?.[0] === currentDay
                )?.netincome;
                const isFirstOfRange =
                  !small &&
                  currentDayReservations?.some(
                    (reservation: any) =>
                      reservation.checkin_at.split(" ")[0] === currentDay
                  );
                const isLastOfRange =
                  !small &&
                  currentDayReservations?.some(
                    (reservation: any) =>
                      reservation.checkout_at.split(" ")[0] === currentDay
                  );

                const isCurrentDayPaidAndUpcoming =
                  (currentDayReservations?.length === 1 &&
                    isPaid &&
                    new Date(
                      currentDayReservations[0].checkout_at.split(" ")[0]
                    ) >= new Date()) ||
                  (currentDayReservations?.length > 1 &&
                    isPaidCheckin &&
                    new Date(
                      currentDayReservations
                        ?.find(
                          (reservation: any) =>
                            reservation.checkin_at.split(" ")?.[0] ===
                            currentDay
                        )
                        ?.checkout_at.split(" ")[0]
                    ) >= new Date());

                const isCurrentDayOwnerAndUpcoming =
                  (currentDayReservations?.length === 1 &&
                    !isPaid &&
                    new Date(
                      currentDayReservations[0].checkout_at.split(" ")[0]
                    ) >= new Date()) ||
                  (currentDayReservations?.length > 1 &&
                    !isPaidCheckin &&
                    new Date(
                      currentDayReservations
                        ?.find(
                          (reservation: any) =>
                            reservation.checkin_at.split(" ")?.[0] ===
                            currentDay
                        )
                        ?.checkout_at.split(" ")[0]
                    ) >= new Date());

                const isCurrentDayPaidAndPrevious =
                  (currentDayReservations?.length === 1 &&
                    isPaid &&
                    new Date(
                      currentDayReservations[0].checkout_at.split(" ")[0]
                    ) < new Date()) ||
                  (currentDayReservations?.length > 1 &&
                    isPaidCheckin &&
                    new Date(
                      currentDayReservations
                        ?.find(
                          (reservation: any) =>
                            reservation.checkin_at.split(" ")?.[0] ===
                            currentDay
                        )
                        ?.checkout_at.split(" ")[0]
                    ) < new Date());

                return (
                  <div
                    style={{
                      position: "relative",
                      visibility:
                        small && currentMonth !== day.getMonth()
                          ? "hidden"
                          : "visible",
                    }}
                    className={classes.day}
                    key={dayNumber}
                  >
                    <p
                      //todo refactor this
                      className={`${
                        format(day, "yyyy-MM-dd") ===
                        format(new Date(), "yyyy-MM-dd")
                          ? small
                            ? isLeftSide
                              ? classes.todayDateSmallLeftSide
                              : classes.todayDateSmall
                            : classes.todayDate
                          : undefined
                      } ${isLeftSide && classes.leftSide}`}
                    >
                      {format(day, "dd")}
                    </p>
                    {small && currentDayReservations?.length ? (
                      <div  className={classes.circleBlock}>
                        {currentDayReservations.length &&
                          currentDayReservations
                            ?.sort(
                              (first: IReservation, second: IReservation) =>
                                isAfter(
                                  new Date(first.checkin_at),
                                  new Date(second.checkin_at)
                                )
                                  ? 1
                                  : -1
                            )
                            .map((reservation: IReservation) => (
                              <span
                                key={reservation?.reservation_id}
                                className={`${classes.circle} ${
                                  reservation.netincome
                                    ? classes.paid
                                    : classes.owner
                                }`}
                              />
                            ))}
                      </div>
                    ) : null}
                    {!small && currentDayReservations?.length > 0 && (
                      <>
                        <div
                          //todo refactor this
                          className={`${classes.reservation} ${
                            isFirstOfRange && classes.beginning
                          } ${isLastOfRange && classes.ending} ${
                            isCurrentDayPaidAndUpcoming
                              ? classes.paid
                              : isCurrentDayOwnerAndUpcoming
                              ? classes.owner
                              : isCurrentDayPaidAndPrevious
                              ? classes.paidPrevious
                              : classes.ownerPrevious
                          } ${
                            currentDayReservations?.length > 1 &&
                            classes.bothBeginning
                          } ${
                            ((currentDayReservations?.length === 1 &&
                              isPaid &&
                              new Date(
                                currentDayReservations[0].checkout_at.split(
                                  " "
                                )[0]
                              ) > new Date() &&
                              new Date(
                                currentDayReservations[0].checkin_at.split(
                                  " "
                                )[0]
                              ) <= new Date()) ||
                              (currentDayReservations.length > 1 &&
                                isPaidCheckin &&
                                new Date(
                                  currentDayReservations
                                    ?.find(
                                      (reservation: any) =>
                                        reservation.checkin_at.split(
                                          " "
                                        )?.[0] === currentDay
                                    )
                                    ?.checkout_at.split(" ")[0]
                                ) > new Date() &&
                                new Date(
                                  currentDayReservations
                                    ?.find(
                                      (reservation: any) =>
                                        reservation.checkin_at.split(
                                          " "
                                        )?.[0] === currentDay
                                    )
                                    ?.checkin_at.split(" ")[0]
                                ) <= new Date())) &&
                            classes.paidCurrent
                          }  ${
                            ((currentDayReservations?.length === 1 &&
                              !isPaid &&
                              new Date(
                                currentDayReservations[0].checkout_at.split(
                                  " "
                                )[0]
                              ) > new Date() &&
                              new Date(
                                currentDayReservations[0].checkin_at.split(
                                  " "
                                )[0]
                              ) <= new Date()) ||
                              (currentDayReservations.length > 1 &&
                                !isPaidCheckin &&
                                new Date(
                                  currentDayReservations
                                    ?.find(
                                      (reservation: any) =>
                                        reservation.checkin_at.split(
                                          " "
                                        )?.[0] === currentDay
                                    )
                                    ?.checkout_at.split(" ")[0]
                                ) > new Date() &&
                                new Date(
                                  currentDayReservations
                                    ?.find(
                                      (reservation: any) =>
                                        reservation.checkin_at.split(
                                          " "
                                        )?.[0] === currentDay
                                    )
                                    ?.checkin_at.split(" ")[0]
                                ) <= new Date())) &&
                            classes.ownerCurrent
                          } 
                          `}
                        >
                          {isFirstOfRange && (
                            <div>
                              {(dayIndex < 6) ? (
                                <div
                                  className={`${classes.sourceIcon} ${
                                    isCurrentDayPaidAndUpcoming
                                      ? classes.paidSvgFill
                                      : isCurrentDayOwnerAndUpcoming
                                      ? classes.ownerSvgFill
                                      : isCurrentDayPaidAndPrevious
                                      ? classes.paidPreviousSvgFill
                                      : classes.ownerPreviousSvgFill
                                  }`}
                                >
                                  {channelToIconMap(currentDayCheckin?.source)}
                                </div>
                              ) : null}
                              {differenceInDays(
                                new Date(currentDayCheckin?.checkout_at),
                                new Date(currentDayCheckin?.checkin_at)
                              ) < 3 &&
                              (isMobileView ? dayIndex > 3 : dayIndex > 4) ? (
                                <div
                                  className={`${(dayIndex < 6) ? classes.tooltipIcon : classes.noSourceIcon} ${
                                    !!channelToIconMap(
                                      currentDayCheckin?.source
                                    ) && classes.withSourceIcon
                                  }`}
                                >
                                  <CustomTooltip
                                    className={`${
                                      isCurrentDayPaidAndUpcoming
                                        ? classes.paidSvgFill
                                        : isCurrentDayOwnerAndUpcoming
                                        ? classes.ownerSvgFill
                                        : isCurrentDayPaidAndPrevious
                                        ? classes.paidPreviousSvgFill
                                        : classes.ownerPreviousSvgFill
                                    }`}
                                    tooltip={
                                      currentDayCheckin?.created_by
                                        ? `${currentDayCheckin?.property_id ? `${currentDayCheckin?.property_id} - ` : ''}${currentDayCheckin?.created_by}${currentDayCheckin?.guest_count ? ` - ${currentDayCheckin?.guest_count} guest${currentDayCheckin?.guest_count > 1 ? 's' : ''}` : ''}`
                                        : `${currentDayCheckin?.property_id ? `${currentDayCheckin?.property_id} - ` : ''}${currentDayCheckin?.guest_name}${currentDayCheckin?.guest_count ? ` - ${currentDayCheckin?.guest_count} guest${currentDayCheckin?.guest_count > 1 ? 's' : ''}` : ''}`
                                    }
                                  />
                                </div>
                              ) : (
                                <div
                                  className={`${classes.reservationContent} ${
                                    (!isPaidCheckin ||
                                      !channelToIconMap(
                                        currentDayCheckin?.source
                                      )) &&
                                    classes.noSourceIcon
                                  }`}
                                >
                                  {!isPaidCheckin ?
                                    differenceInDays(
                                      new Date(currentDayCheckin?.checkout_at),
                                      new Date(currentDayCheckin?.checkin_at)
                                    ) < 2 ? (
                                      <div
                                        style={{marginLeft: (differenceInDays(
                                          new Date(currentDayCheckin?.checkout_at),
                                          new Date(currentDayCheckin?.checkin_at)
                                        ) >= 1) ? '-40px' : '-25px'}}
                                        className={`${(dayIndex < 6) ? classes.tooltipIcon : classes.noSourceIcon} ${
                                          !!channelToIconMap(
                                            currentDayCheckin?.source
                                          ) && classes.withSourceIcon
                                        }`}
                                      >
                                        <CustomTooltip
                                            className={`${
                                              isCurrentDayPaidAndUpcoming
                                                ? classes.paidSvgFill
                                                : isCurrentDayOwnerAndUpcoming
                                                ? classes.ownerSvgFill
                                                : isCurrentDayPaidAndPrevious
                                                ? classes.paidPreviousSvgFill
                                                : classes.ownerPreviousSvgFill
                                            }`}
                                            tooltip={
                                              currentDayCheckin?.created_by
                                              ? `${currentDayCheckin?.property_id ? `${currentDayCheckin?.property_id} - ` : ''}${currentDayCheckin?.created_by}${currentDayCheckin?.guest_count ? ` - ${currentDayCheckin?.guest_count} guest${currentDayCheckin?.guest_count > 1 ? 's' : ''}` : ''}`
                                              : `${currentDayCheckin?.property_id ? `${currentDayCheckin?.property_id} - ` : ''}${currentDayCheckin?.guest_name}${currentDayCheckin?.guest_count ? ` - ${currentDayCheckin?.guest_count} guest${currentDayCheckin?.guest_count > 1 ? 's' : ''}` : ''}`
                                            }
                                          />
                                      </div> ) : (
                                    <span className={classes.name}>
                                      {ownerBlockName}
                                    </span>
                                  ) : (
                                    differenceInDays(
                                      new Date(currentDayCheckin?.checkout_at),
                                      new Date(currentDayCheckin?.checkin_at)
                                    ) < 2 ? (
                                      <div
                                        style={{marginLeft: (differenceInDays(
                                          new Date(currentDayCheckin?.checkout_at),
                                          new Date(currentDayCheckin?.checkin_at)
                                        ) >= 1) ? '-40px' : '-25px'}}
                                        className={`${(dayIndex < 6) ? classes.tooltipIcon : classes.noSourceIcon} ${
                                          !!channelToIconMap(
                                            currentDayCheckin?.source
                                          ) && classes.withSourceIcon
                                        }`}
                                      >
                                        <CustomTooltip
                                            className={`${
                                              isCurrentDayPaidAndUpcoming
                                                ? classes.paidSvgFill
                                                : isCurrentDayOwnerAndUpcoming
                                                ? classes.ownerSvgFill
                                                : isCurrentDayPaidAndPrevious
                                                ? classes.paidPreviousSvgFill
                                                : classes.ownerPreviousSvgFill
                                            }`}
                                            tooltip={
                                              currentDayCheckin?.created_by
                                              ? `${currentDayCheckin?.property_id ? `${currentDayCheckin?.property_id} - ` : ''}${currentDayCheckin?.created_by}${currentDayCheckin?.guest_count ? ` - ${currentDayCheckin?.guest_count} guest${currentDayCheckin?.guest_count > 1 ? 's' : ''}` : ''}`
                                              : `${currentDayCheckin?.property_id ? `${currentDayCheckin?.property_id} - ` : ''}${currentDayCheckin?.guest_name}${currentDayCheckin?.guest_count ? ` - ${currentDayCheckin?.guest_count} guest${currentDayCheckin?.guest_count > 1 ? 's' : ''}` : ''}`
                                            }
                                          />
                                      </div>
                                    ) : (
                                      <div>
                                        <span className={classes.name}>
                                          {currentDayCheckin?.guest_name?.split(' ')?.[0]}
                                        </span>
                                        <span
                                          className={`${classes.name} ${
                                            classes.guests
                                          } ${
                                            isCurrentDayPaidAndUpcoming ||
                                            isCurrentDayOwnerAndUpcoming
                                              ? classes.upcoming
                                              : classes.previous
                                          }`}
                                        >
                                          <span>
                                            {`${currentDayCheckin.guest_count} ${
                                              currentDayCheckin.guest_count === 1
                                              ? "guest"
                                              : "guests"
                                            }`}
                                            {' • '}
                                            {`$${(toPrettyNumber(currentDayCheckin?.netincome)) || toPrettyNumber(currentDayCheckin?.netincome_usd)}`}
                                          </span>
                                        </span>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {currentDayReservations?.length > 1 && (
                          <div
                            //todo refactor this
                            className={`${classes.reservation} ${
                              isPaidCheckout
                                ? new Date(
                                    currentDayReservations[0].checkout_at
                                  ) >= new Date()
                                  ? classes.paid
                                  : classes.paidPrevious
                                : new Date(
                                    currentDayReservations?.find(
                                      (reservation: any) =>
                                        reservation.checkout_at.split(" ")?.[0]
                                    )
                                  ) >= new Date()
                                ? classes.owner
                                : classes.ownerPrevious
                            } ${
                              currentDayReservations?.length > 1 &&
                              classes.bothEnding
                            } ${
                              isPaidCheckin &&
                              new Date(
                                currentDayReservations
                                  ?.find(
                                    (reservation: any) =>
                                      reservation.checkout_at.split(
                                        " "
                                      )?.[0] === currentDay
                                  )
                                  ?.checkout_at.split(" ")[0]
                              ) > new Date() &&
                              new Date(
                                currentDayReservations
                                  ?.find(
                                    (reservation: any) =>
                                      reservation.checkout_at.split(
                                        " "
                                      )?.[0] === currentDay
                                  )
                                  ?.checkin_at.split(" ")[0]
                              ) <= new Date() &&
                              classes.paidCurrent
                            } ${
                              !isPaidCheckin &&
                              new Date(
                                currentDayReservations
                                  ?.find(
                                    (reservation: any) =>
                                      reservation.checkout_at.split(
                                        " "
                                      )?.[0] === currentDay
                                  )
                                  ?.checkout_at.split(" ")[0]
                              ) > new Date() &&
                              new Date(
                                currentDayReservations
                                  ?.find(
                                    (reservation: any) =>
                                      reservation.checkout_at.split(
                                        " "
                                      )?.[0] === currentDay
                                  )
                                  ?.checkin_at.split(" ")[0]
                              ) <= new Date() &&
                              classes.ownerCurrent
                            }`}
                          />
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      {!small && <Legend legendData={calendarLegend} />}
    </>
  );
};
