import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GENERAL_OCCUPANCY_REQUEST,
  PERFOMANCE_HIGHLIGHTS_REQUEST
} from "../../../../redux/actions";
import { generalOccupancySelector } from "../../../../redux/selectors/occupancyRateSelector";
import { perfomanceHighlightsSelector } from "../../../../redux/selectors/perfomanceHighlightsSelector";
import { periodList } from "../../../../utils/constants/periodLists";
import { PeriodDropdown } from "../../../common/PeriodDropdown/PeriodDropdown";
import { Highlight } from "../../../common/BasicHighlight/BasicHighlight";
import { OccupancyHighlight } from "../../../common/CircleOccupancyHighlight/CircleOccupancyHighlight";
import classes from "./PerfomanceHighlights.module.scss";
import { isNumber } from "../../../../utils/commonUtils";
import { L3060D, L12M, N30D } from '../../../../utils/constants/filterConstants';
import {isMobileModeSelector} from "../../../../redux/selectors/appStatusSelector";

export const PerfomanceHighlights = () => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(L12M);
  const previousPeriod = period === N30D ? L12M : L3060D;
  const data = useSelector(perfomanceHighlightsSelector(period));
  const previousData = useSelector(perfomanceHighlightsSelector(previousPeriod));
  const isMobileView = useSelector(isMobileModeSelector);
  const {
    uniqueReservations,
    totalBookedNights,
    avarageDailyRates,
    netReservationIncome
  } = data || {};

  const {
    uniqueReservations: previousUniqueReservations,
    totalBookedNights: previousTotalBookedNights,
    avarageDailyRates: previousAvarageDailyRates,
    netReservationIncome: previousNetReservationIncome,
  } = previousData || {};

  const generalOccupancy = useSelector(generalOccupancySelector(period));

  useEffect(() => {
    dispatch({
      type: GENERAL_OCCUPANCY_REQUEST,
      payload: { param: period }
    });
    dispatch({
      type: PERFOMANCE_HIGHLIGHTS_REQUEST,
      payload: { param: period }
    });
  }, [dispatch, period]);

  return (
    <div className={classes.perfomanceHighlightsWrapper}>
      <>
        <div className={classes.highlightsHeader}>
          <h4>Reservations</h4>
          <PeriodDropdown
            className={classes.outlinedDropdown}
            period={period}
            periodList={periodList}
            setPeriod={setPeriod}
          />
        </div>
        <div className={classes.highlights}>
          {isNumber(Number(generalOccupancy?.total)) && (
            <OccupancyHighlight
              showIndicator={period !== L12M && period !== N30D}
              label={"Occupancy Rate"}
              rate={generalOccupancy?.total}
              previousRate={generalOccupancy?.last30_60_days}
              reverse={isMobileView}
            />
          )}
          {isNumber(uniqueReservations) && (
            <>
              <Highlight
                className={classes.highlight}
                label="Reservations"
                tooltip={"Excluding owner blocks"}
                value={uniqueReservations}
                previousValue={previousUniqueReservations}
                reverse={isMobileView}
                showDynamicIndicator={period !== L12M && period !== N30D}
              />
              <Divider flexItem orientation={isMobileView ? 'horizontal' : 'vertical'} />
            </>
          )}
          {isNumber(totalBookedNights) && (
            <>
              <Highlight
                className={classes.highlight}
                label="Booked Nights"
                tooltip={
                  "This is the average number of nights that your property was occupied for a given reservation"
                }
                value={totalBookedNights}
                previousValue={previousTotalBookedNights}
                reverse={isMobileView}
                showDynamicIndicator={period !== L12M && period !== N30D}
              />
              <Divider flexItem orientation={isMobileView ? 'horizontal' : 'vertical'} />
            </>
          )}
          {isNumber(avarageDailyRates) && (
            <>
              <Highlight
                className={classes.highlight}
                label="Average Daily Rate"
                prependSymbol={"$"}
                tooltip={
                  "This is the average rental revenue earned for an occupied room per day. This number is calculated by taking the Net Accommodation Fare substracting the Cleaning, Channel,  Credit Card and Guest Insurance Fees as well as Taxes and dividing it by the Total Number of Nights Booked"
                }
                value={avarageDailyRates}
                previousValue={previousAvarageDailyRates}
                reverse={isMobileView}
                showDynamicIndicator={period !== L12M && period !== N30D}
              />
              <Divider flexItem orientation={isMobileView ? 'horizontal' : 'vertical'} />
            </>
          )}
          {isNumber(netReservationIncome) && (
            <>
              <Highlight
                className={classes.highlight}
                label="Net Reservation Income:"
                prependSymbol={"$"}
                tooltip={
                  "This is your net income from secured reservations minus fees. This number is calculated by taking the Net Accomodation Fare subtracting the Channel, Credit Card and Booking (AH) Fees"
                }
                value={netReservationIncome}
                previousValue={previousNetReservationIncome}
                showDynamicIndicator={period !== L12M && period !== N30D}
              />
              {isMobileView ? <Divider flexItem orientation="horizontal" /> : null}
            </>
          )}
        </div>
      </>
    </div>
  );
};
