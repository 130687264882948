import {
  createBrowserRouter,
  matchRoutes,
  Navigate,
  useLocation
} from "react-router-dom";
import { ErrorPage } from "../views/pages/ErrorPage/ErrorPage";
import { NotFoundPage } from "../views/pages/404ErrorPage/404ErrorPage";
import { CalendarPage } from "../views/pages/CalendarPage/CalendarPage";
import { CalendarReservationsPage } from "../views/pages/ReservationsPage/ReservationsPage";
import { ContactPage } from "../views/pages/ContactPage/ContactPage";
import { DocumentsPage } from "../views/pages/DocumentsPage/DocumentsPage";
import { ListingsPage } from "../views/pages/ListingsPage/ListingsPage";
import { LoginPage } from "../views/pages/LoginPage/LoginPage";
import { OccupancyPage } from "../views/pages/OccupancyPage/OccupancyPage";
import { PerfomancePage } from "../views/pages/PerfomancePage/PerfomancePage";
import { RatingPage } from "../views/pages/RatingPage/RatingPage";
import { RevenuePage } from "../views/pages/RevenuePage/RevenuePage";
import { SettingsPage } from "../views/pages/SettingsPage/SettingsPage";

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/",
    element: <Navigate to="/perfomance" replace />,
    errorElement: <ErrorPage />
  },
  {
    path: "/perfomance",
    element: <PerfomancePage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/occupancy",
    element: <OccupancyPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/revenue",
    element: <RevenuePage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/rating",
    element: <RatingPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/calendar",
    element: <CalendarPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/calendar/reservations",
    element: <CalendarReservationsPage />
  },
  {
    path: "/listings",
    element: <ListingsPage />,
    errorElement: <ErrorPage />
  },
  /*{
    path: "/documents",
    element: <DocumentsPage />,
    errorElement: <ErrorPage />
  },*/
  {
    path: "/settings",
    element: <SettingsPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/contacts",
    element: <ContactPage />,
    errorElement: <ErrorPage />
  },
  {
    path: "*",
    element: <NotFoundPage />,
  }
];

export const router = createBrowserRouter(routes, { basename: "/" });

export const useCurrentPath = () => {
  const location = useLocation();
  const [{ pathname }]: any = matchRoutes(routes, location);

  return pathname;
};
