import { requestHandler, getServiceURL } from "../utils/apiUtils";

export class UserLoginAPI {
  static get loginUserServiceName() {
    return "LOGIN_USER";
  }

  static get forgotPasswordServiceName() {
    return "FORGOT_PASSWORD";
  }

  static async loginUser(data: {
    property_id: string;
    password: string;
  }): Promise<any> {
    const url = getServiceURL(UserLoginAPI.loginUserServiceName);
    const response = await requestHandler(url, data);
    return response?.data;
  }

  static async forgotPassword(data: { property_id: string }): Promise<any> {
    const url = getServiceURL(UserLoginAPI.forgotPasswordServiceName);
    const response = await requestHandler(url, data);
    return response?.data;
  }
}
