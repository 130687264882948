export const SHOW_MONTH = "Month";

export const SHOW_YEAR = "Year";

export const AIRBNB = "airbnb";

export const BOOKING = "booking";

export const VRBO = "vrbo";

export const EXPEDIA = "expedia";