import { requestHandler, getServiceURL } from "../utils/apiUtils";
import { IOccupancyAPI } from "../views/interfaces";

export class RevenueAPI {
  static get getTotalRevenueServiceName() {
    return "GET_REVENUES";
  }

  static get getYearlyRevenueServiceName() {
    return "GET_YEARLY_REVENUE";
  }

  static get getRevenueByMonthServiceName() {
    return "GET_YEARLY_REVENUE_BY_MONTH";
  }

  static get getPerNightRevenueServiceName() {
    return "GET_PER_NIGHT_REVENUE";
  }

  static get getPerNightMarketRevenueServiceName() {
    return "GET_PER_NIGHT_MARKET_REVENUE";
  }

  static get getBookingReservationsServiceName() {
    return "GET_BOOKING_RESERVATIONS";
  }

  static get getExpediaReservationsServiceName() {
    return "GET_EXPEDIA_RESERVATIONS";
  }

  static get getVrboReservationsServiceName() {
    return "GET_VRBO_RESERVATIONS";
  }

  static get getAirbnbReservationsServiceName() {
    return "GET_AIRBNB_RESERVATIONS";
  }

  static async getTotalRevenue(): Promise<number> {
    const url = getServiceURL(RevenueAPI.getTotalRevenueServiceName);
    const response = await requestHandler(url);
    return response?.data?.revenue;
  }

  static async getYearlyRevenue(): Promise<object> {
    const url = getServiceURL(RevenueAPI.getYearlyRevenueServiceName);
    const response = await requestHandler(url);
    return response?.data?.data;
  }

  static async getRevenueByMonth({ year = "2022" }: IOccupancyAPI): Promise<number> {
    const url = getServiceURL(RevenueAPI.getRevenueByMonthServiceName);
    const response = await requestHandler(url, { year });
    return response?.data?.MarketOccupancyRate;
  }

  static async getPerNightRevenue(params?: {
    param?: string;
  }): Promise<object> {
    const url = getServiceURL(RevenueAPI.getPerNightRevenueServiceName);
    const response = await requestHandler(url, params);
    return response?.data;
  }

  static async getPerNightMarketRevenue(params?: {
    param?: string;
  }): Promise<object> {
    const url = getServiceURL(RevenueAPI.getPerNightMarketRevenueServiceName);
    const response = await requestHandler(url, params);
    return response?.data;
  }

  static async getBookingReservations(params?: {
    param?: string;
  }): Promise<number> {
    const url = getServiceURL(RevenueAPI.getBookingReservationsServiceName);
    const response = await requestHandler(url, params);
    return response?.data?.count;
  }

  static async getExpediaReservations(params?: {
    param?: string;
  }): Promise<number> {
    const url = getServiceURL(RevenueAPI.getExpediaReservationsServiceName);
    const response = await requestHandler(url, params);
    return response?.data?.count;
  }

  static async getVrboReservations(params?: {
    param?: string;
  }): Promise<number> {
    const url = getServiceURL(RevenueAPI.getVrboReservationsServiceName);
    const response = await requestHandler(url, params);
    return response?.data?.count;
  }

  static async getAirbnbReservations(params?: {
    param?: string;
  }): Promise<number> {
    const url = getServiceURL(RevenueAPI.getAirbnbReservationsServiceName);
    const response = await requestHandler(url, params);
    return response?.data?.count;
  }
}
